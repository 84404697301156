import { slate, slateDark, indigo, indigoDark } from "@radix-ui/colors"
import { css } from "styled-components"

const theme = { ...slate }
const darkTheme = { ...slateDark }

const themeCSS = css`
  --base1: ${Object.values(theme)[0]};
  --base2: ${Object.values(theme)[1]};
  --base3: ${Object.values(theme)[2]};
  --base4: ${Object.values(theme)[3]};
  --base5: ${Object.values(theme)[4]};
  --base6: ${Object.values(theme)[5]};
  --base7: ${Object.values(theme)[6]};
  --base8: ${Object.values(theme)[7]};
  --base9: ${Object.values(theme)[8]};
  --base10: ${Object.values(theme)[9]};
  --base11: ${Object.values(theme)[10]};
  --base12: ${Object.values(theme)[11]};

  --accent11: ${indigo.indigo11};
  --accent12: ${indigo.indigo12};
`

const darkThemeCSS = css`
  --base1: ${Object.values(darkTheme)[0]};
  --base2: ${Object.values(darkTheme)[1]};
  --base3: ${Object.values(darkTheme)[2]};
  --base4: ${Object.values(darkTheme)[3]};
  --base5: ${Object.values(darkTheme)[4]};
  --base6: ${Object.values(darkTheme)[5]};
  --base7: ${Object.values(darkTheme)[6]};
  --base8: ${Object.values(darkTheme)[7]};
  --base9: ${Object.values(darkTheme)[8]};
  --base10: ${Object.values(darkTheme)[9]};
  --base11: ${Object.values(darkTheme)[10]};
  --base12: ${Object.values(darkTheme)[11]};

  --accent11: ${indigoDark.indigo11};
  --accent12: ${indigoDark.indigo12};
`

export { theme, darkTheme, themeCSS, darkThemeCSS }
