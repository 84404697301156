import * as React from "react"
import styled from "styled-components"
import { Caption } from "../theme/typography"

const FooterContainer = styled.div`
  padding: 48px 0px 0px 0px;
  display: grid;
  color: var(--base11);
`

const Footer = () => {
  return (
    <FooterContainer>
      <Caption>&copy; {new Date().getFullYear()} Pablo Olavarrieta</Caption>
    </FooterContainer>
  )
}

export default Footer
