import * as React from "react"
// import Navbar from "./Navbar"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { theme, darkTheme, themeCSS, darkThemeCSS } from "../theme/theme"
import "../theme/reset.css"
import Helmet from "react-helmet"
import Footer from "./Footer"

const GlobalStyle = createGlobalStyle`
  :root{
    ${themeCSS}

    @media (prefers-color-scheme: dark) {
      ${darkThemeCSS}
    }
  }

  body{
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: var(--base12);
    background-color: var(--base2);
  }

  html{
    background: var(--base2);
  }
`

const LayoutContainer = styled.div`
  display: grid;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  background: var(--base2);
`

const LayoutColumn = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 0;
  justify-items: stretch;
  justify-content: stretch;
  max-width: 600px;
  padding: 24px;
`

const Layout = ({ props, children }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyle />
      <Helmet>
        <meta
          name="theme-color"
          content={Object.values(theme)[1]}
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content={Object.values(darkTheme)[1]}
          media="(prefers-color-scheme: dark)"
        />
      </Helmet>
      <LayoutContainer>
        <LayoutColumn>
          {children}
          <Footer />
        </LayoutColumn>
      </LayoutContainer>
    </ThemeProvider>
  )
}

export default Layout
