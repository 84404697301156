import styled from "styled-components"

const Body = styled.p`
  font-size: 18px;
  line-height: 150%;
  margin: 0;
  padding: 0;
  font-weight: 300;
`

const Bold = styled.span`
  font-size: 18px;
  line-height: 150%;
  margin: 0;
  padding: 0;
  font-weight: 500;
`

const Caption = styled.span`
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  padding: 0;
  font-weight: 400;
`

const Header1 = styled.h1`
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
`

const Header3 = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
`

const Header5 = styled.h5`
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
`

const SubHeader = styled.h6`
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
`

export { Body, Bold, Header1, Header3, Header5, Caption, SubHeader }
